import React, { useState, useEffect } from 'react';
import {
  Play,
  Pause,
  RotateCcw,
  Infinity,
  Settings,
  Volume2,
  VolumeX,
  Bell,
  Plus,
  Trash2,
  Check,
  Brain,
  Coffee,
  ArrowLeft,
} from 'lucide-react';
import timerSound from '../assets/timer.mp3'; // Ensure you have your sound file at this path

export default function Component() {
  const [timeLeft, setTimeLeft] = useState(1500);
  const [breakTime, setBreakTime] = useState(300);
  const [isRunning, setIsRunning] = useState(false);
  const [isFocus, setIsFocus] = useState(true);
  const [isInfinite, setIsInfinite] = useState(true);
  const [sessions, setSessions] = useState(0);
  const [isMuted, setIsMuted] = useState(false);
  const [showSettings, setShowSettings] = useState(false); // Control the visibility of settings
  const [currentTime, setCurrentTime] = useState(new Date());
  const [notification, setNotification] = useState(null);
  const [tasks, setTasks] = useState([]);
  const [newTask, setNewTask] = useState('');

  // Reference for the timer sound
  const audioRef = React.useRef(new Audio(timerSound));

  useEffect(() => {
    // Update current time every second
    const timer = setInterval(() => setCurrentTime(new Date()), 1000);
    return () => clearInterval(timer);
  }, []);

  useEffect(() => {
    let interval;
    if (isRunning && timeLeft > 0) {
      // Decrease time left every second when running
      interval = setInterval(() => setTimeLeft((prev) => prev - 1), 1000);
    } else if (timeLeft === 0) {
      // Play sound when the timer reaches 0
      if (!isMuted) {
        audioRef.current.currentTime = 0;
        audioRef.current.play().catch((error) => {
          console.log('Error playing audio:', error);
        });
      }

      // Switch between focus and break sessions
      if (isFocus) {
        setSessions((prev) => prev + 1);
        setNotification('Time for a break!');
      } else {
        setNotification('Focus time!');
      }

      if (isInfinite) {
        setTimeLeft(isFocus ? breakTime : 1500);
        setIsFocus((prev) => !prev);
      } else {
        setIsRunning(false);
      }
    }
    return () => clearInterval(interval);
  }, [isRunning, timeLeft, isFocus, isInfinite, breakTime, isMuted]);

  useEffect(() => {
    // Clear notification after 3 seconds
    if (notification) {
      const timer = setTimeout(() => setNotification(null), 3000);
      return () => clearTimeout(timer);
    }
  }, [notification]);

  // Play sound when starting the timer
  const toggleTimer = () => {
    if (!isRunning && !isMuted) {
      audioRef.current.currentTime = 0;
      audioRef.current.play().catch((error) => {
        console.log('Error playing audio:', error);
      });
    }
    setIsRunning(!isRunning);
  };

  const handlePresetSelect = (focusMinutes, breakMinutes) => {
    setTimeLeft(focusMinutes * 60);
    setBreakTime(breakMinutes * 60);
    setIsRunning(false);
    setIsFocus(true);
    setShowSettings(false); // Hide settings after selecting a preset
  };

  const formatTime = (seconds) => {
    // Format seconds into MM:SS
    const mins = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${mins.toString().padStart(2, '0')}:${secs
      .toString()
      .padStart(2, '0')}`;
  };

  const addTask = (e) => {
    e.preventDefault();
    if (newTask.trim()) {
      setTasks([
        ...tasks,
        { id: Date.now(), text: newTask, completed: false },
      ]);
      setNewTask('');
    }
  };

  const toggleTask = (id) => {
    // Toggle task completion status
    setTasks(
      tasks.map((task) =>
        task.id === id ? { ...task, completed: !task.completed } : task
      )
    );
  };

  const deleteTask = (id) => {
    // Remove task from the list
    setTasks(tasks.filter((task) => task.id !== id));
  };

  return (
    <div className="relative min-h-screen bg-gradient-to-br from-zinc-900 to-black font-sans flex flex-col">
      {/* Container for main content and sidebar */}
      <div className="flex flex-col md:flex-row flex-grow">
        {/* Main Content */}
        <main className="flex-grow relative p-8 flex flex-col overflow-y-auto" role="main" id="main-content">
          {/* Notification */}
          {notification && (
            <div className="absolute top-8 left-1/2 transform -translate-x-1/2 bg-orange-500 text-white px-6 py-3 rounded-lg shadow-lg animate-fade-in-down">
              <div className="flex items-center gap-2">
                <Bell size={20} />
                {notification}
              </div>
            </div>
          )}

          {/* Date and Time */}
          <div className="flex justify-between items-center mb-8">
            <div>
              <div className="text-gray-400 text-lg font-medium tracking-wide">
                {currentTime.toLocaleDateString('en-US', {
                  month: 'long',
                  day: 'numeric',
                  year: 'numeric',
                })}
              </div>
              <div className="text-white text-4xl font-semibold tracking-tight mt-1">
                {currentTime.toLocaleTimeString('en-US', {
                  hour: 'numeric',
                  minute: '2-digit',
                  hour12: true,
                })}
              </div>
            </div>
            <button
              onClick={() => setIsMuted(!isMuted)}
              aria-label={isMuted ? 'Unmute' : 'Mute'}
              className="text-orange-500 hover:text-white transition-colors p-2 rounded-full hover:bg-zinc-800"
            >
              {isMuted ? <VolumeX size={24} /> : <Volume2 size={24} />}
            </button>
          </div>

          {/* Timer Section */}
          <div className="flex-grow flex flex-col items-center justify-center">
            <div className="flex items-center gap-8 mb-4">
              <div className="text-5xl font-medium tracking-wide flex items-center gap-2">
                {isFocus ? (
                  <>
                    <Brain className="text-orange-500 mr-2" size={40} />
                    <span className="text-white font-semibold">Focus</span>
                  </>
                ) : (
                  <>
                    <Coffee className="text-white mr-2" size={40} />
                    <span className="text-white font-semibold">Break</span>
                  </>
                )}
              </div>
              <div className="text-orange-500 text-2xl font-light">
                Sessions: {sessions}
              </div>
            </div>
            <div
              onClick={toggleTimer}
              style={{
                fontSize: 'min(20vw, 40vh)',
                lineHeight: '1',
                letterSpacing: '0.01em',
                color: 'white',
                cursor: 'pointer',
              }}
              className="font-bold select-none transition-all duration-300"
            >
              {formatTime(timeLeft)}
            </div>
            <div className="text-gray-400 text-3xl mt-4 font-medium tracking-wide">
              {isFocus
                ? `Break: ${formatTime(breakTime)}`
                : `Focus: ${formatTime(1500)}`}
            </div>
          </div>

          {/* Task List */}
          <div className="mt-8 max-w-md mx-auto w-full">
            <h2 className="text-white text-xl font-semibold mb-4">Tasks</h2>
            <form onSubmit={addTask} className="flex mb-4">
              <input
                type="text"
                value={newTask}
                onChange={(e) => setNewTask(e.target.value)}
                className="flex-grow bg-zinc-700 text-white px-3 py-2 rounded-l-md focus:outline-none"
                placeholder="Add a task..."
              />
              <button
                type="submit"
                aria-label="Add Task"
                className="bg-orange-500 text-white px-3 py-2 rounded-r-md hover:bg-orange-600"
              >
                <Plus size={20} />
              </button>
            </form>
            <div
              className="overflow-y-auto max-h-32 pr-2"
              style={{
                scrollbarWidth: 'thin',
                scrollbarColor: '#f97316 #1f2937',
              }}
            >
              {tasks.map((task) => (
                <div
                  key={task.id}
                  className="flex items-center mb-2 bg-zinc-700/50 p-2 rounded"
                >
                  <button
                    onClick={() => toggleTask(task.id)}
                    aria-label={
                      task.completed ? 'Mark as incomplete' : 'Mark as complete'
                    }
                    className="mr-2 text-white"
                  >
                    {task.completed ? (
                      <Check size={20} className="text-green-500" />
                    ) : (
                      <div className="w-5 h-5 border-2 border-white rounded-sm" />
                    )}
                  </button>
                  <span
                    className={`flex-grow ${
                      task.completed
                        ? 'line-through text-gray-500'
                        : 'text-white'
                    }`}
                  >
                    {task.text}
                  </span>
                  <button
                    onClick={() => deleteTask(task.id)}
                    aria-label="Delete Task"
                    className="text-red-500 hover:text-red-700"
                  >
                    <Trash2 size={20} />
                  </button>
                </div>
              ))}
            </div>
          </div>

          {/* Sidebar buttons below the task list on small screens */}
          <div className="mt-4 md:hidden grid grid-cols-4 gap-4">
            <button
              onClick={toggleTimer}
              aria-label={isRunning ? 'Pause Timer' : 'Start Timer'}
              className={`w-full p-4 rounded-full flex items-center justify-center transition-all duration-300 ${
                isRunning
                  ? 'bg-orange-500 hover:bg-orange-600'
                  : 'bg-zinc-800 hover:bg-zinc-700'
              } shadow-lg hover:scale-105`}
            >
              {isRunning ? (
                <Pause className="text-white" size={32} />
              ) : (
                <Play className="text-orange-500 group-hover:text-white" size={32} />
              )}
            </button>

            <button
              onClick={() => {
                setTimeLeft(1500);
                setIsRunning(false);
                setIsFocus(true);
              }}
              aria-label="Reset Timer"
              className="w-full p-4 rounded-full bg-zinc-800 hover:bg-zinc-700 flex items-center justify-center transition-all duration-300 shadow-lg hover:scale-105"
            >
              <RotateCcw className="text-orange-500 group-hover:text-white" size={32} />
            </button>

            <button
              onClick={() => setIsInfinite(!isInfinite)}
              aria-label={isInfinite ? 'Disable Loop' : 'Enable Loop'}
              className={`w-full p-4 rounded-full flex items-center justify-center transition-all duration-300 ${
                isInfinite
                  ? 'bg-orange-500 hover:bg-orange-600'
                  : 'bg-zinc-800 hover:bg-zinc-700'
              } shadow-lg hover:scale-105`}
            >
              <Infinity
                className={isInfinite ? 'text-white' : 'text-orange-500'}
                size={32}
              />
            </button>

            <button
              onClick={() => setShowSettings(!showSettings)} // Show/hide settings on small screens
              aria-label="Settings"
              className={`w-full p-4 rounded-full flex items-center justify-center transition-all duration-300 ${
                showSettings
                  ? 'bg-orange-500 hover:bg-orange-600'
                  : 'bg-zinc-800 hover:bg-zinc-700'
              } shadow-lg hover:scale-105`}
            >
              <Settings
                className={showSettings ? 'text-white' : 'text-orange-500'}
                size={32}
              />
            </button>
          </div>

          {/* Settings on mobile */}
          {showSettings && (
            <div className="md:hidden bg-zinc-800/50 backdrop-blur-lg p-4 rounded-lg mt-4">
              <h3 className="text-white text-lg mb-2">Settings</h3>
              {[{ focus: 25, break: 5 }, { focus: 45, break: 15 }, { focus: 50, break: 10 }].map(
                (preset) => (
                  <button
                    key={`${preset.focus}/${preset.break}`}
                    onClick={() => handlePresetSelect(preset.focus, preset.break)}
                    aria-label={`Set timer to ${preset.focus} minutes focus and ${preset.break} minutes break`}
                    className="w-full py-2 text-white text-sm hover:bg-orange-500 rounded-lg transition-colors duration-300 mb-2"
                  >
                    {preset.focus}/{preset.break}
                  </button>
                )
              )}
              {/* Back button */}
              <button
                onClick={() => setShowSettings(false)}
                aria-label="Back"
                className="w-full py-2 mt-2 text-white text-sm bg-zinc-700 hover:bg-zinc-600 rounded-lg transition-colors duration-300"
              >
                <ArrowLeft className="inline-block mr-2" size={16} />
                Back
              </button>
            </div>
          )}
        </main>

        {/* Sidebar on large screens */}
        <aside className="w-32 hidden md:flex flex-col bg-zinc-900/50 backdrop-blur-lg">
          {/* Conditionally render buttons or settings */}
          {!showSettings ? (
            // Buttons with slide-in animation
            <div className="flex-1 flex flex-col items-center justify-center gap-8 py-8 transition-all duration-500">
              <button
                onClick={toggleTimer}
                aria-label={isRunning ? 'Pause Timer' : 'Start Timer'}
                className={`w-16 h-16 rounded-full flex items-center justify-center transition-all duration-300 ${
                  isRunning
                    ? 'bg-orange-500 hover:bg-orange-600'
                    : 'bg-zinc-800 hover:bg-zinc-700'
                } shadow-lg hover:scale-105`}
              >
                {isRunning ? (
                  <Pause className="text-white" size={32} />
                ) : (
                  <Play
                    className="text-orange-500 group-hover:text-white"
                    size={32}
                  />
                )}
              </button>

              <button
                onClick={() => {
                  setTimeLeft(1500);
                  setIsRunning(false);
                  setIsFocus(true);
                }}
                aria-label="Reset Timer"
                className="w-16 h-16 rounded-full bg-zinc-800 hover:bg-zinc-700 flex items-center justify-center transition-all duration-300 shadow-lg hover:scale-105"
              >
                <RotateCcw
                  className="text-orange-500 group-hover:text-white"
                  size={32}
                />
              </button>

              <button
                onClick={() => setIsInfinite(!isInfinite)}
                aria-label={isInfinite ? 'Disable Loop' : 'Enable Loop'}
                className={`w-16 h-16 rounded-full flex items-center justify-center transition-all duration-300 ${
                  isInfinite
                    ? 'bg-orange-500 hover:bg-orange-600'
                    : 'bg-zinc-800 hover:bg-zinc-700'
                } shadow-lg hover:scale-105`}
              >
                <Infinity
                  className={isInfinite ? 'text-white' : 'text-orange-500'}
                  size={32}
                />
              </button>

              <button
                onClick={() => setShowSettings(true)}
                aria-label="Settings"
                className={`w-16 h-16 rounded-full flex items-center justify-center transition-all duration-300 ${
                  showSettings
                    ? 'bg-orange-500 hover:bg-orange-600'
                    : 'bg-zinc-800 hover:bg-zinc-700'
                } shadow-lg hover:scale-105`}
              >
                <Settings
                  className="text-orange-500 group-hover:text-white"
                  size={32}
                />
              </button>
            </div>
          ) : (
            // Settings with slide-down animation
            <div className="flex-1 flex flex-col items-center justify-center py-8 transition-all duration-500">
              <div className="w-full px-4">
                <h3 className="text-white text-lg mb-4 text-center">Settings</h3>
                {[{ focus: 25, break: 5 }, { focus: 45, break: 15 }, { focus: 50, break: 10 }].map(
                  (preset) => (
                    <button
                      key={`${preset.focus}/${preset.break}`}
                      onClick={() => handlePresetSelect(preset.focus, preset.break)}
                      aria-label={`Set timer to ${preset.focus} minutes focus and ${preset.break} minutes break`}
                      className="w-full py-2 text-white text-sm bg-zinc-800 hover:bg-orange-500 rounded-lg transition-colors duration-300 mb-2"
                    >
                      {preset.focus}/{preset.break}
                    </button>
                  )
                )}
                {/* Back button */}
                <button
                  onClick={() => setShowSettings(false)}
                  aria-label="Back"
                  className="w-full py-2 mt-2 text-white text-sm bg-zinc-700 hover:bg-zinc-600 rounded-lg transition-colors duration-300"
                >
                  <ArrowLeft className="inline-block mr-2" size={16} />
                  Back
                </button>
              </div>
            </div>
          )}
        </aside>
      </div>

      {/* Footer */}
      <footer className="text-center py-4 bg-zinc-900 text-gray-400 w-full">
        <p>&copy; {new Date().getFullYear()} El Desarrollador. All rights reserved.</p>
      </footer>
    </div>
  );
}
