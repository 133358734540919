import React from 'react';
import ReactDOM from 'react-dom';
import './index.css'; // Si tienes estilos
import App from './App'; // Asegúrate que el componente App esté correctamente importado

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);
